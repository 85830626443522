<script setup lang="ts">
import IndexInfluencer from './Influencers.vue'

import { useGetWelcomeSpotlight } from '~/composables/useGetWelcomeSpotlight'

import type { CuratorCarouselFragment } from '~/graphql/generated'
import type { Locale } from '~/types/locale'

type Props = CuratorCarouselFragment

defineProps<Props>()

const { getLandingPageInfluencers } = useGetWelcomeSpotlight()

const { locale, fallbackLocale } = useI18n()

const { data: landingPageInfluencers } = await useAsyncData(
  'LandingPageInfluencers',
  () => {
    return getLandingPageInfluencers(
      locale.value as Locale,
      fallbackLocale.value.toString() as Locale,
    )
  },
  {
    default: () => ({}),
  },
)
</script>

<template>
  <IndexInfluencer
    :genre-to-influencer-id-map="landingPageInfluencers"
    :emphasis-type="emphasizedCuratorType"
  />
</template>

<script setup lang="ts">
import FeaturedUserCard from './FeaturedUserCard.vue'

import DatoSectionContainer from '~/components/dato/DatoSectionContainer.vue'

import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { provideGetSexyUsers } from '~/api-core/Welcome/References'

import type { FeaturedUserFragment } from '~/graphql/generated'
import type { Locale } from '~/types/locale'
import type { IndexSexyUser } from '~/types/references'

type UserCategories = Record<string, IndexSexyUser[]>

type Props = FeaturedUserFragment

const props = withDefaults(defineProps<Props>(), {
  title: '',
  items: () => [],
})

const { locale, fallbackLocale } = useI18n()
const { coreFetch } = useProvideCoreFetch()
const carouselListRefs = ref<HTMLElement[]>([])

const { data: featuredUsers } = useLazyAsyncData('featuredUsers', async () => {
  const getSexyUsers = provideGetSexyUsers(coreFetch)
  return await getSexyUsers(
    locale.value as Locale,
    fallbackLocale.value as Locale,
  )
})

const userTypes = ref<UserCategories>(
  props.items.reduce((acc, currentItem) => {
    if (currentItem.carouselType) {
      acc[currentItem.carouselType] =
        featuredUsers.value?.filter(
          (user) => user.type === currentItem.carouselType,
        ) || []
    }

    return acc
  }, {} as UserCategories),
)

onMounted(async () => {
  // set equal number of items in each user type so that the speed of the croll animation matches all carousels
  setEqualNumberOfItemsInEachUserType(getMaxItemsFromUserTypes())
  reverseOrderOfEvenCarousels()

  // wait for next tick to ensure the DOM is updated before duplicating items
  await nextTick()
  // duplicate items to create infinite scroll effect
  duplicateItems()
})

const animationTime = computed(() => {
  const cssVar = {
    '--animation-time': '0s',
  }

  if (!carouselListRefs.value[0] || !import.meta.client) return cssVar

  // using querySelector to access the item because dom access is not granted to component template refs
  const featuredUserCardEl = document.querySelector('.featuredUserCard')
  const FEATURED_USER_ITEM_WIDTH = featuredUserCardEl?.clientWidth ?? 184
  const TIME_TO_SCROLL_ONE_ITEM_IN_SECONDS = 6
  const PIXELS_PER_SECOND =
    FEATURED_USER_ITEM_WIDTH / TIME_TO_SCROLL_ONE_ITEM_IN_SECONDS

  const time = carouselListRefs.value[0].clientWidth / PIXELS_PER_SECOND
  cssVar['--animation-time'] = `${time}s`
  return cssVar
})

function getMaxItemsFromUserTypes() {
  return Math.max(
    ...Object.values(userTypes.value ?? []).map((userType) => userType.length),
  )
}

function setEqualNumberOfItemsInEachUserType(maxItems: number) {
  if (!userTypes.value || Object.keys(userTypes.value).length < 2) return

  Object.keys(userTypes.value).forEach((userType) => {
    if (
      !userTypes.value ||
      !userTypes.value[userType] ||
      !userTypes.value[userType].length
    )
      return

    const userTypeLength = userTypes.value[userType].length
    const diff = maxItems - userTypeLength

    if (diff > 0) {
      userTypes.value[userType] = [
        ...userTypes.value[userType],
        ...userTypes.value[userType].slice(0, diff),
      ]

      // recursive call to add items to equal max
      setEqualNumberOfItemsInEachUserType(maxItems)
    }
  })
}

function duplicateItems() {
  if (!carouselListRefs.value.length) return

  carouselListRefs.value.forEach((carouselListRef) => {
    const clonedCarouselListItems = Array.from(carouselListRef.children)

    clonedCarouselListItems.forEach((clonedCarouselListItem) => {
      const clonedCarouselListItemClone = clonedCarouselListItem.cloneNode(
        true,
      ) as HTMLElement
      clonedCarouselListItemClone.setAttribute('aria-hidden', 'true')
      carouselListRef.appendChild(clonedCarouselListItemClone)
    })
  })
}

function reverseOrderOfEvenCarousels() {
  if (!userTypes.value || Object.keys(userTypes.value).length < 2) return

  Object.keys(userTypes.value).forEach((userType, index) => {
    if (
      userTypes.value &&
      userTypes.value[userType] &&
      userTypes.value[userType].length &&
      index % 2 === 1
    )
      userTypes.value[userType] = userTypes.value[userType]?.reverse()
  })
}
</script>

<template>
  <DatoSectionContainer>
    <div
      class="-tw-mx-lg tw-flex tw-flex-col tw-gap-y-5xl tw-overflow-hidden md:-tw-mx-xl lg:tw-mx-0"
      :style="animationTime"
    >
      <div
        v-for="(item, index) in items"
        :key="`${index}-${item.id}`"
        class="tw-flex tw-flex-col tw-gap-x-2xl tw-gap-y-2xl lg:tw-grid lg:tw-items-center odd:lg:tw-grid-cols-[1fr_2fr] even:lg:tw-grid-cols-[2fr_1fr]"
      >
        <div
          v-if="
            userTypes &&
            item.carouselType &&
            userTypes[item.carouselType]?.length
          "
          class="tw-flex tw-flex-col tw-items-center tw-gap-y-sm tw-px-lg tw-text-center md:tw-px-0 lg:tw-text-left"
          :class="{
            'lg:tw-order-2': index % 2 === 1,
          }"
        >
          <h3 v-if="item.title" class="tw-text-h5 lg:tw-self-start">
            {{ item.title }}
          </h3>
          <p
            v-if="item.subtitle"
            class="tw-text-body-sm tw-max-w-[480px] lg:tw-max-w-[384px]"
          >
            {{ item.subtitle }}
          </p>
        </div>
        <div
          v-if="
            userTypes &&
            item.carouselType &&
            userTypes[item.carouselType]?.length
          "
          class="motion-safe:tw-overflow-hidden motion-reduce:tw-overflow-y-scroll lg:tw-border-x lg:tw-border-default-color"
        >
          <ul
            ref="carouselListRefs"
            class="tw-flex tw-w-max tw-items-center tw-gap-x-sm tw-px-lg md:tw-px-xl lg:tw-h-[214px] lg:tw-px-sm"
            :class="[
              index % 2 === 1 ? 'animate-scroll-right' : 'animate-scroll-left',
            ]"
          >
            <FeaturedUserCard
              v-for="(featuredUser, featuredUserIndex) in userTypes[
                item.carouselType
              ]"
              :key="`${featuredUserIndex}-${featuredUser.id}`"
              class="featuredUserCard"
              :title="featuredUser.title"
              :image-url="featuredUser.picture"
              :subtitle="featuredUser.subtitle"
            />
          </ul>
        </div>
      </div>
    </div>
  </DatoSectionContainer>
</template>

<style scoped lang="scss">
@keyframes scrollLeft {
  to {
    transform: translateX(-50%);
  }
}

@media screen and (prefers-reduced-motion: no-preference) {
  .animate-scroll-left,
  .animate-scroll-right {
    animation: scrollLeft var(--animation-time) linear infinite;
  }

  .animate-scroll-right {
    animation-direction: reverse;
  }

  .animate-scroll-left:hover,
  .animate-scroll-right:hover {
    animation-play-state: paused;
  }
}
</style>

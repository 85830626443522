import type { CoreFetch } from '~/types/CoreFetch'
import type { Locale } from '~/types/locale'
import type { IndexSexyUser } from '~/types/references'

export function provideGetSexyUsers(coreFetch: CoreFetch) {
  return async function (
    locale: Locale,
    fallbackLocale: Locale = 'en',
  ): Promise<IndexSexyUser[]> {
    try {
      return (
        (await coreFetch.$get<IndexSexyUser[]>(`/welcome/sexy-users/`, {
          query: {
            lang: locale,
            fallback_lang: fallbackLocale,
          },
        })) || []
      )
    } catch (e) {
      return []
    }
  }
}

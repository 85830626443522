import type { CoreFetch } from '~/types/CoreFetch'
import type { Locale } from '~/types/locale'

export function provideWelcomeSpotlight(coreFetch: CoreFetch) {
  return function (locale: Locale, fallbackLocale: Locale) {
    return coreFetch.$get<Record<string, { id: number }[]>>(
      `/welcome/spotlight/`,
      {
        query: {
          lang: locale,
          fallback_lang: fallbackLocale,
        },
        timeout: 1500,
      },
    )
  }
}

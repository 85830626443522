<script setup lang="ts">
/**
 * The content of this component is a mix between
 * front/components/index/pricing.vue and front/pages/index.vue
 */

import { storeToRefs } from 'pinia'

import {
  currencyConvertHelper,
  getAfterFloats,
} from '~/directives/ConvertToTargetCurrency'

import CurrencyOrder from '~/components/shared/currency/order.vue'
import CurrencySwapper from '~/components/shared/currency/swapper.vue'
import VTooltip from '~/components/ui/VTooltip.vue'

import { useMiscCurrencyStore } from '~/stores/miscCurrency'

type Props = {
  text?: string | null
}

defineProps<Props>()

const { initCurrency } = useCurrencySetter()

const {
  IS_USING_CONVERSION: IS_USING_CURRENCY_CONVERSION,
  targetCurrency: TARGET_CURRENCY,
} = storeToRefs(useMiscCurrencyStore())

const targetCurrencyValue = computed<number>(() => {
  return currencyConvertHelper(2, true)
})

const targetCurrencyFloatingPoints = computed<string>(() =>
  getAfterFloats(targetCurrencyValue.value),
)

useLazyAsyncData(() => initCurrency())
</script>

<template>
  <span class="tw-inline-flex tw-items-center">
    <CurrencyOrder class="tw-inline">
      <span :key="TARGET_CURRENCY" class="currencyWrap">
        <span>{{ Math.floor(targetCurrencyValue).toFixed(0) }}</span
        ><span v-if="IS_USING_CURRENCY_CONVERSION" class="floatingPoint">
          {{ $t(`currency.floatIndicator.${TARGET_CURRENCY}`)
          }}{{ targetCurrencyFloatingPoints }}
        </span>
      </span>
    </CurrencyOrder>
    <CurrencySwapper class="tw-ml-sm" />
    <VTooltip v-if="text">
      <template #default>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="tw-ml-sm tw-h-xl tw-w-xl tw-cursor-help tw-text-gray-500 hover:tw-text-gray-700"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clip-rule="evenodd"
          />
        </svg>
      </template>
      <template #tooltipText>
        {{ text }}
      </template>
    </VTooltip>
  </span>
</template>
